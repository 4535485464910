<template>
  <v-container>
    <v-card elevation="2" link to="/transactions">
      <v-card-title>
        <v-icon>mdi-arrow-left</v-icon>
        <span>NFT ID {{tokenInfo.nft_id}}</span>
      </v-card-title>
    </v-card>
    <v-card class="body" v-if="tokenInfo.contract_type === 'DIGITALIP'">
      <v-row class="content">
        <v-col cols="12" md="3">
          <v-card>
            <v-img
              :src="(tokenInfo.token_image_url && tokenInfo.token_image_url.indexOf('//collection.yuhu.tech') !== -1) ? tokenInfo.token_image_url: tokenInfo.token_display_url"
            />
          </v-card>
        </v-col>

        <v-col class="ml-md-5" cols="12" md="8">
          <v-expansion-panels v-model="panel" multiple accordion>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h4>基本信息</h4>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <v-row v-for="(item, index) in tokenInfo.baseInfo" :key="index">
                    <v-col cols="4" md="2">{{item[0]}}</v-col>
                    <v-col cols="8" md="10">{{item[1]}}</v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <h4>属性</h4>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col class="property_card">
                    <v-card
                      class="card"
                      v-for="(item, index) in tokenInfo.properties"
                      :key="index"
                      min-width="200"
                      elevation-12
                      hover="true"
                      color="blue lighten-5"
                    >
                      <div>{{item.attribute_type}}</div>
                      <h3>{{item.attribute_value}}</h3>
                      <div>{{item.attribute_percent}}%</div>
                    </v-card>
                  </v-col>
                </v-row>

              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <h4>描述</h4>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{tokenInfo.token_description}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="body" v-else>
      <v-card-title>基本信息</v-card-title>
      <v-row class="content pt-0">
        <v-col cols="12" md="3">
          <v-card>
            <v-img
              :src="tokenInfo.token_display_url"
            />
          </v-card>
        </v-col>

        <v-col class="mb-12 ml-md-5" cols="12" md="8">
          <v-card class="right_card">
            <v-row v-for="(item, index) in tokenInfo.baseInfo" :key="index">
              <v-col cols="3" md="2">{{item[0]}}</v-col>
              <v-col cols="9" md="10">{{item[1]}}</v-col>
            </v-row>
        </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-card>
      <v-card-title>交易记录</v-card-title>
      <div v-if="!transactions_loaded" class="box d-flex justify-space-around align-center flex-column flex-sm-row fill-height">
        <v-btn variant="flat" @click="loadTransactions()">
          点击加载
        </v-btn>
      </div>
      <v-data-table
        v-if="transactions_loaded"
        class="elevation-1"
        disable-sort
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        @update:page="onPagination"
      >
        <template v-slot:[`item.tx_hash`]="{ item }">
          <RippleLink :to="`/transactions/${item.tx_hash}`">
            <Ellipsis :width="width">
              {{ item.tx_hash }}
            </Ellipsis>
          </RippleLink>
        </template>
        <template v-slot:[`item.from`]="{ item }">
          <RippleLink :to="`/address/${item.trading_record.from}`">
            <Ellipsis :width="width">
              {{ item.trading_record.from }}
            </Ellipsis>
          </RippleLink>
        </template>

        <template v-slot:[`item.to`]="{ item }">
          <RippleLink :to="`/address/${item.trading_record.to}`">
            <Ellipsis :width="width">
              {{ item.trading_record.to }}
            </Ellipsis>
          </RippleLink>
        </template>

        <template v-slot:[`item.type`]="{ item }">
          {{ getTransactionName(item.trading_record) }}
        </template>
        <template v-slot:[`item.pended_at`]="{ item }">
          {{ YMDHms(item.pended_at) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { RippleLink, Ellipsis } from '@/components';
import { Token, Transaction } from '@/api';
import { YMDHms, YMD, valid, getTransactionName } from '@/utils';

export default {
  name: 'Token',
  components: { RippleLink, Ellipsis },
  data: () => ({
    transactions_loaded: false,
    token_description: 'wwwwww',
    width: '240px',
    table: {
      headers: [
        { text: '交易哈希', value: 'tx_hash' },
        { text: '交易发起者', value: 'from' },
        { text: '交易接收者', value: 'to' },
        { text: '交易类型', value: 'type' },
        { text: '区块高度', value: 'block_number' },
        { text: '交易时间', value: 'pended_at' },
      ],
      items: [],
      total: 0,
    },
    tokenInfo: {
      baseInfo: {},
      token_display_url: '',
    },
    panel: [0],
  }),
  mounted() {
    this.getTokenInfo();
    // this.getTransactions(1);
  },

  methods: {
    YMDHms,
    YMD,
    getTransactionName,

    loadTransactions() {
      this.transactions_loaded = true;
      this.getTransactions(1);
    },

    getAddress() {
      return this.$route.params.hash;
    },

    getTokenId() {
      return this.$route.params.tokenId;
    },

    getTokenInfo() {
      Token.getNFTTokenInfo(this.getAddress(), this.getTokenId())
        .then((res) => {
          if (res.nft_token.contract_type === 'DIGITALIP') {
            this.tokenInfo = {
              baseInfo: {
                token_name: ['NFT名称', res.nft_token.token_name],
                owner_address: ['拥有者', res.nft_token.owner_address],
                contract_address: ['合约地址', res.nft_token.contract_address],
                token_id: ['元编号', res.nft_token.token_id],
                contract_type: ['合约类型', '系列集合'],
                artist: ['创作者', res.nft_token.artist],
                digital_name: ['作品名称', res.nft_token.digital_name],
                publisher: ['发行人', res.nft_token.publisher_address],
              },
              properties: res.nft_token.token_attributes,
              token_description: res.nft_token.token_description,
              nft_id: res.nft_token.nft_id,
              token_display_url: res.nft_token.token_display_url,
              token_image_url: res.nft_token.token_image_url,
              contract_type: 'DIGITALIP',
            };
          } else {
            this.tokenInfo = {
              baseInfo: {
                owner_address: ['拥有者', res.nft_token.owner_address],
                contract_address: ['合约地址', res.nft_token.contract_address],
                token_display_url: ['作品URL', res.nft_token.token_display_url],
                token_digest: ['作品摘要', res.nft_token.token_digest],
                contract_type: ['合约类型', '数字文创'],
                artist: ['创作者', res.nft_token.artist],
                digital_name: ['作品名称', res.nft_token.digital_name],
                publisher: ['发行人', res.nft_token.publisher_address],
              },
              token_display_url: res.nft_token.token_display_url,
              nft_id: res.nft_token.nft_id,
            };
          }
      });
    },


    getTransactions(page) {
      const conditions = [];

      conditions.push({
        contract_addresses: [this.getAddress()],
        token_ids: [this.getTokenId()],
        event_details: [{ name: 'Transfer' }],
      });

      Transaction.describeEventDetails({
        limit: 10,
        offset: (page - 1) * 10,
        conditions,
      }).then((res) => {
        this.table = {
          ...this.table,
          items: res.event_details,
          total: res.total_count,
        };
      });
    },

    onPagination(page) {
      this.getTransactions(page);
    },
  },
};
</script>

<style lang="scss", scoped>
.body {
  margin: 36px 0;
  
  .content {
    padding: 20px;
    .right {
      border: 1px solid gainsboro;
      border-radius: 10px;
    }
    .right_card {
      padding: 12px;
    }

    .property_card {
      display: flex;
      flex-wrap: wrap;

      .card {
        margin-left: 20px;
        margin-bottom: 20px;
        padding: 5px;
        text-align: center;
        border: 1px solid #B3E5FC;
      }
    }
  }
}

.box {
  padding-bottom: 15px;
}
</style>
